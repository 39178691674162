import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Navigation from './components/Navigation';
import Footer from './components/Footer'
import 'bootstrap/dist/css/bootstrap.css'
import "./App.css"
import ContactUs from './components/ContactUs';
import Privarcy from './components/Privarcy';
import Terms from './components/Terms';




const App=()=> {
  return (
    <Router>
      <div className="App">
       
       
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/2" element={<Home />} />
          <Route path="/sadig" element={<Home />} />
          <Route path="/nazarli" element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<Privarcy />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
         
        </Routes>
      
      </div>
      
    </Router>
  );
}

export default App;