export const detectVPN = async ({ ipInfo, webRTCIp }) => {
    const checks = [
      // IP mismatch check
      Boolean(webRTCIp && ipInfo.ip !== webRTCIp),
      
      // Timezone mismatch check
      (() => {
        const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return Boolean(ipInfo.timezone && browserTimezone !== ipInfo.timezone);
      })(),
      
      // Common VPN ports check
      [1194, 443, 1723, 500].includes(parseInt(window.location.port)),
      
      // WebRTC blocking check
      !webRTCIp && typeof RTCPeerConnection !== 'undefined'
    ];
  
    return checks.some(result => result === true);
  };