const IP_SERVICES = [
    'https://ipapi.co/json/',
    'https://ip.seeip.org/jsonip',
    'https://api.ipify.org?format=json'
  ];
  
  const fetchWithTimeout = async (url, timeout = 5000) => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);
  
    try {
      const response = await fetch(url, { signal: controller.signal });
      clearTimeout(timeoutId);
      return response;
    } catch (error) {
      clearTimeout(timeoutId);
      throw error;
    }
  };
  
  export const getIpInfo = async () => {
    let lastError;
  
    for (const service of IP_SERVICES) {
      try {
        const response = await fetchWithTimeout(service);
        const data = await response.json();
        
        return {
          ip: data.ip,
          city: data.city || '',
          country: data.country_name || data.country || '',
          timezone: data.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
          isp: data.org || data.organization || ''
        };
      } catch (error) {
        lastError = error;
        continue;
      }
    }
  
    throw lastError;
  };