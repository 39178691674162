import { useState, useEffect } from 'react';
import { detectWebRTCLeak } from '../services/webRTCService';
import { getIpInfo } from '../services/ipService';
import { detectVPN } from '../services/vpnDetectionService';

export const useLocationDetection = () => {
  const [locationInfo, setLocationInfo] = useState(null);
  const [loading2, setLoading2] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const detectLocation = async () => {
      try {
        const [ipInfo, webRTCIp] = await Promise.all([
          getIpInfo(),
          detectWebRTCLeak()
        ]);

        const isVpn = await detectVPN({ ipInfo, webRTCIp });

        setLocationInfo({
          ...ipInfo,
          isVpn,
          webRTCIp: webRTCIp || undefined,
        });
        
        setError(null);
      } catch (err) {
        console.error('Location detection error:', err);
        setError(err instanceof Error ? err.message : 'Failed to detect location');
        
        if (locationInfo) {
          setLocationInfo({
            ...locationInfo,
            error: err instanceof Error ? err.message : 'Partial information only'
          });
        }
      } finally {
        setLoading2(false);
      }
    };

    detectLocation();
  }, []);

  return { locationInfo, loading2, error };
};