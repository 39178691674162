export const detectWebRTCLeak = () => {
    return new Promise((resolve) => {
      try {
        const pc = new RTCPeerConnection({ 
          iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] 
        });
        
        pc.onicecandidate = (event) => {
          if (event.candidate) {
            const ipMatch = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/
              .exec(event.candidate.candidate);
            
            if (ipMatch && ipMatch[1]) {
              resolve(ipMatch[1]);
              pc.close();
            }
          }
        };
  
        pc.createDataChannel('');
        pc.createOffer()
          .then(offer => pc.setLocalDescription(offer))
          .catch(() => resolve(null));
  
        setTimeout(() => {
          pc.close();
          resolve(null);
        }, 5000);
      } catch (error) {
        console.error('WebRTC detection error:', error);
        resolve(null);
      }
    });
  };